



















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Group, SentInvitation } from "@/core/models";
import { Component, Watch } from "vue-property-decorator";

import {
  GroupUsersCard,
  AddUserCard,
  GroupVideosCard,
  GroupName,
  GroupPostsCard,
} from "./components";

import InvitedUsers from "./components/InvitedUsers.vue";

@Component({
  components: {
    GroupUsersCard,
    AddUserCard,
    GroupVideosCard,
    GroupName,
    GroupPostsCard,
    InvitedUsers,
  },
})
export default class GroupDetails extends Vue {
  @Getter("groups/loading") loading!: boolean;
  @Getter("groups/retrieved") retrieved!: boolean;
  @Getter("groups/getGroups") groups!: Group[];
  @Action("groups/getGroups") getGroups!: () => Promise<void>;
  @Getter("showSnackbar") showSnackbar!: boolean;
  @Getter("snackbarText") snackbarText!: string;

  group = {} as Group;
  invitations: SentInvitation[] = [];
  get isManager() {
    return this.group.IsManager;
  }
  get videos() {
    return this.group.Videos;
  }
  get posts() {
    return this.group.Posts;
  }
  get visibilityStatus() {
    return this.group.IsVisible ? "visible" : "invisible";
  }

  addInv(inv: SentInvitation[]) {
    this.invitations = [...this.invitations, ...inv];
  }

  @Watch("$route")
  routeChanged() {
    if (this.$route.name != "groupDetails") return;

    let id = Number(this.$route.params.id);
    if (!this.groups.some(g => g.ID == id)) this.$router.push("/groups");
    else this.group = this.groups.find(g => g.ID == id)!;
  }

  @Watch("hasRetrieved", { immediate: true })
  async onGroupsRetrieved() {
    if (!this.retrieved) await this.getGroups();
    let id = Number(this.$route.params.id);
    if (!this.groups.some(g => g.ID == id)) this.$router.push("/groups");
    else this.group = this.groups.find(g => g.ID == id)!;
  }
}
